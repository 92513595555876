<script>
  //  import config from "../config";

  // TODO PUT LOADING STATUS AND SPINNER
  import {
    AsYouType,
    isValidNumber,
    parsePhoneNumber,
    parseIncompletePhoneNumber
  } from "libphonenumber-js";

  let fromServer = null;
  let phone = null;
  let isValid = null;
  let fetching = false;
  let urlParams = [];
  let queryPhone = null;
  let code = "";
  const url = new URLSearchParams(window.location.search);
  for (var pair of url.entries()) {
    urlParams.push({ [pair[0]]: pair[1] });
  }

  urlParams.forEach(param => {
    if (param.phone) {
      let parsedPhone = `+${parseIncompletePhoneNumber(param.phone)}`;
      if (isValidNumber(parsedPhone)) {
        queryPhone = parsedPhone;
      }
    }

    if (param.code) {
      code = param.code;
    }
  });

  const formatNumber = e => {
    phone = new AsYouType("US").input(e.target.value);

    if (isValidNumber(`+1${parseIncompletePhoneNumber(phone)}`)) {
      isValid = true;
    } else {
      isValid = false;
      return;
    }
  };

  const createCustomer = async payload => {
    const endpoint = "ENDPOINT_URL";

    fetching = true;
    try {
      const response = await fetch(`https://app.omsg.io/api/organizations/lg/optIn`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });

      fromServer = await response.json();
    } catch (error) {
      throw error;
    } finally {
      fetching = false;
    }
  };

  const handleFormSubmit = e => {
    let data = {};

    Array.from(e.target).forEach(input => {
      if (input.id) {
        data[input.id] = String(input.value);
      }
    });

    data.phone = queryPhone || data.phone;
    data.phone = parsePhoneNumber(data.phone, 'US').number;

    urlParams.forEach(param => {
      if (data.exclusiveCode) {
        return;
      }
      data = { ...param, ...data };
    });
    const payload = {
      phone: data.phone,
      context: { ...data },
      conversation: '377b4a63-f573-4077-b272-1b99e64561c4'
    };
    createCustomer(payload);
  };
</script>

<section class="container">
  <header class="header">
    <div class="logo">
      <img alt="LG" src="images/LG_Logo.png" />
    </div>
    <picture>
      <source
        alt="LG"
        media="(min-width: 1024px)"
        srcset="images/Hero_Image.jpg" />
      <img alt="LG" src="images/Hero_Image_Mobile.jpg" />
    </picture>
  </header>
  <main>
    <div class="subscription">
      <div class="stack-container">
        <div>
          <p class="legend">
            All great things have a starting point and LG wants to help you take
            the first step.
          </p>
          <p class="legend">
            Receive expert guidance and tips that will help you get started with everything from:
          </p>
          <ul class="bullet-list">
            <li>Fitness</li>
            <li>Career moves</li>
            <li>Sustainable Lifestyles</li>
            <li>Sustainable Happiness</li>
            <li>And much more...</li>
          </ul>
        </div>
      </div>
      <form id="contact-info" on:submit|preventDefault={handleFormSubmit}>
        <div class="full-name">
          <label for="firstname">First Name</label>
          <input id="firstname" type="text" placeholder="First Name" required />
          <label for="lastname">Last Name</label>
          <input id="lastname" type="text" placeholder="Last Name" required />
        </div>
        {#if !queryPhone}
          <label for="phone">Phone Number</label>
          <input
            bind:value={phone}
            id="phone"
            type="tel"
            on:input={formatNumber}
            placeholder="Mobile Phone Number"
            required />
        {/if}
        {#if !fromServer}
          <div class="info-group">
            {#if fetching}
              <div class="success-message">Loading...</div>
            {:else}
              <input type="submit" value="Text Me" />
            {/if}
            {#if isValid === false}
              <div class="warning-group">
                <img
                  alt="invalid Mobile phone"
                  src="images/missing_field_icon.png" />
                <p>Enter a valid mobile number</p>
              </div>
            {/if}
          </div>
        {:else}
          <div class="info-group">
            <div class="success-message">Thank You!</div>
            <div class="warning-group">
              <img
                alt="invalid Mobile phone"
                src="images/missing_field_icon.png" />
              <p>Please check your phone</p>
            </div>
          </div>
        {/if}
        <footer>
          <div class="openmessage-logo">
            <a href="http://openmessage.io" target="_blank">
              <img
                alt="Powered by OpenMessage"
                src="images/Powered_By_OpenMessage.png" />
            </a>
          </div>
          <p>
            By clicking “TEXT ME”, you are agreeing to receive recurring autodialed promotional text messages from LG Electronics USA.
            Consent not required to make any purchase. Text <b>STOP</b> to stop receiving texts. Message and data rates may apply.
            Terms and Privacy Policy apply:
            <a
              class="link"
              href="https://www.lg.com/us/privacy"
              target="_blank">
              Click here to read the LG Privacy Policy.
            </a>
          </p>
        </footer>
      </form>
    </div>
  </main>
</section>
